import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import Loading from "../shared/Loading";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const DesignType = ({ next, back ,setpperback1,setpperback2,setpperback3,setpperback4}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({ stone: null });
  const [stonedata, setStoneData] = useState([]);
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [mbDisabled, setmbDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  const handleItemClick = (type, id) => {    
    setmbDisabled(true)
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [type]: id,
    }));
  };

  useEffect(() => {
    if (selectedItems.stone) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItems]);

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView(); 
    if (selectedItems.stone) {
      const crownType = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.name;
      const subcategory = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.category_id;
      const crownId = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.id;
      updateFormData("DesignCrown", {
        crowntype: crownType,
        sub_category_id: subcategory,
        crownid:crownId
      });
      next(); 
    } else {
      //setError("Please select crown");
    }
  };

  useEffect(() => {  
    fetch(`${apiurl}/ring-builder/crown`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
      method: "POST",
      body: JSON.stringify({ parent_id: formData.MainCategories.category_id }),
    })
      .then((response) => response.json())
      .then((stonedata) => {
        setStoneData(stonedata.crown);
          handleItemClick('stone',formData.DesignCrown.crownid) 
        setLoading(false); // Set loading to false when data is fetched
        
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false on error as well
      });
  }, []);

  return (
    <div>
      <div className="flex">
        <div>
          <h1 className="mb-textleft">
            {" "}
            
            {formData.MainCategories.category_name}{" "}
          </h1>
          <p className="lightfont mb-textleft">
             <SubTitle></SubTitle>
          </p>
        </div>
        <div className="show-mobile">
          <img
            src={formData.MainCategories.category_img}
            width="120px"
            alt="Main Image"
          />{" "}
        </div>
      </div>

      <Stepper   onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4}   activeStep={2}></Stepper>

      <div className="show-mobile">
      { mbDisabled && <h4 className="mbselected">Selected</h4>} 
        <ul className="flex selectedvallist">
          <li> {formData.CenterStone.stonevalue} </li>
          <li> {formData.CenterStone.shapevalue} </li>
          <li> {formData.CenterStone.sizevalue} </li>

          {selectedItems.stone && (
            <li className="selected-value">
              {stonedata.find((stone) => stone.id === selectedItems.stone).name}
            </li>
          )}
        </ul>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p hide-mobile">
          <div className="mainImage  ">
            <div className="bg-white">
              <img
                src={formData.MainCategories.category_img}
                alt="Main Image"
              />
            </div>
            <ul className="flex selectedvallist">
              <li> {formData.CenterStone.stonevalue} </li>
              <li> {formData.CenterStone.shapevalue} </li>
              <li> {formData.CenterStone.sizevalue} </li>

              {selectedItems.stone && (
                <li className="selected-value">
                  {
                    stonedata.find((stone) => stone.id === selectedItems.stone)
                      .name
                  }
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-5">
          {error && <div className="text-red text-center">{error}</div>}
          {/* Content for the second column */}
          <div className="space-y-6">
            <AccordionItem
              title={
                <div className="text-left">
                  <span>Select ring crown</span>
                  <p className="lightfont">
                    Also known as the ring head, your center stone is mounted
                    here.
                  </p>
                </div>
              }
              content={
                loading ? ( // Show loader if loading is true
                  <Loading />
                ) : (
                  <ul className="grid grid-cols-2  sm:grid-cols-1  gap-4 singlelist customlist-mb">
                    {stonedata.map((stone) => (
                      <li
                        className={`flex justify-between ${
                          selectedItems.stone === stone.id ? "active" : ""
                        }`}
                        onClick={() => handleItemClick("stone", stone.id)}
                        key={stone.id}
                      >
                        <span className="flex gap-2">
                          <img width={40} src={stone.url} />
                          <div className="line-inherit">
                            <div>
                              {stone.name}
                              {stone.is_popular == 1 && (
                                <span className="popular-tag">Popular</span>
                              )}
                            </div>
                            <p className="lightfont">{stone.description}</p>
                          </div>
                        </span>
                        <input
                          type="radio"
                          checked={selectedItems.stone === stone.id}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                  </ul>
                )
              }
              isActive={activeIndex === 0}
              handleClick={() => handleClick(0)}
            />
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={back}>
              {" "}
              <span>{"<"}</span> Go back
            </button>
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit}
            >
              Next <span>{">"}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignType;
