import React  from "react";
import { useForms } from '../actions/FormContext';

function Stepper({ activeStep,onStepClick1,onStepClick2,onStepClick3,onStepClick4 }) {
  const {  formData  } = useForms();
   
 
  const catname = "Engagement Ring" 
  const catname2 = "Dress Ring" 

 
  return (
    <div> 
     
      <div className="customsteper justify-center" style={{ margin:"20px 0px 40px 0px"}}> 
      <div className='steppercontainer flex items-center '>       
        <div  onClick={activeStep > 1 ?() => onStepClick1(18): null} className={`flex flex-col items-center ${activeStep > 1 ? 'activeicon' : '' }  ${activeStep === 1 ? 'completedicon' : '' }`}>            
          <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
           1 {/* <IoDiamondOutline /> */}
          </div>
          <div  className={`mt-2 text-sm icontext-sm  ${activeStep >= 1 ? 'activesteptext' : '' }`}>
          {formData.MainCategories.category_name === "Engagement Ring"  ?
            <div className="lgnowrap"> Center stone </div> : 
            <div  >Style </div>
          }
            </div>
        </div>
        {    
formData.MainCategories.category_name !== "Wedding Band" &&
        <div className="flex items-center justify-center h-10 pl-1 pr-1 w-full"> 
          <div className={`vline w-full ${activeStep >= 1 ? 'activeline' : ''}`} />
        </div>  
}

{    
formData.MainCategories.category_name !== "Wedding Band" &&
        <div  onClick={activeStep > 2 ?() => onStepClick2(18): null}  className={`flex flex-col items-center ${activeStep > 2 ? 'activeicon' : '' } ${activeStep === 2 ? 'completedicon' : ''}`}>
          <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
            2 {/* <TbArrowsCross /> */}
          </div>
          <div     className={`mt-2 text-sm icontext-sm  ${activeStep >= 2 ? 'activesteptext' : '' }`}>
          {formData.MainCategories.category_name === "Engagement Ring"  ?
            <div> Ring crown </div> :
            formData.MainCategories.category_name === "Dress Ring"  ? 
            <div>Stone  </div>:
            formData.MainCategories.category_name === "Wedding Band"  ? 
            <div>Type</div>
            :<div>Stone</div>
            } 
            </div>
        </div>
}
        
        <div className="flex items-center justify-center h-10 pl-1 pr-1 w-full">
          <div className={`vline w-full ${activeStep >= 2 ? 'activeline' : ''}`} />
        </div> 
        <div onClick={activeStep > 3 ?() => onStepClick3(1):null} className={`flex flex-col items-center ${activeStep > 3 ? 'activeicon' : '' } ${activeStep === 3 ? 'completedicon' : ''}`}>
          <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
            3 {/* <CgRing /> */}
          </div>
          <div  className={`mt-2 text-sm icontext-sm  ${activeStep >= 3 ? 'activesteptext' : '' }`}>
          {formData.MainCategories.category_name === "Engagement Ring"  ?
            <div> Ring shank  </div> :
            formData.MainCategories.category_name === "Dress Ring"  ? 
            <div>Metal </div>:
            formData.MainCategories.category_name === "Wedding Band"  ? 
            <div>Stone </div>:
            <div>Metal </div>
            }
             </div>
        </div>
        <div className="flex items-center justify-center h-10 pl-1 pr-1 w-full"> 
          <div className={`vline w-full ${activeStep >= 3 ? 'activeline' : ''}`} />
        </div> 
        <div  onClick={activeStep > 4 ?() => onStepClick4(1):null}  className={`flex flex-col items-center ${activeStep > 4 ? 'activeicon' : '' } ${activeStep === 4 ? 'completedicon' : ''}`}>
          <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
           4 {/* <PiCirclesThree /> */}
          </div>
          <div className={`mt-2 text-sm icontext-sm  ${activeStep >= 4 ? 'activesteptext' : '' }`}>
            
            {formData.MainCategories.category_name === "Engagement Ring"  ?
            <div> Metal</div> :
            formData.MainCategories.category_name === "Dress Ring"  ? 
            <div>Aesthetic </div>:
            formData.MainCategories.category_name === "Wedding Band"  ? 
            <div>Metal</div>:
            <div>Aesthetic </div>
            }
            </div>
        </div>

        {
        formData.MainCategories.category_name.trim() === catname &&
        <div className="flex items-center justify-center h-10 pl-1 pr-1 w-full">
          <div className={`vline w-full ${activeStep >= 4 ? 'activeline' : ''}`} />
        </div>
        }
        {
        formData.MainCategories.category_name.trim() === catname2 &&
        <div className="flex items-center justify-center h-10 pl-1 pr-1 w-full">
          <div className={`vline w-full ${activeStep >= 4 ? 'activeline' : ''}`} />
        </div>
        }

          {  (formData.MainCategories.category_name.trim()=== catname)    &&
                  <div  onClick={activeStep > 5 ?() => onStepClick3(1):null} className={`flex flex-col items-center ${activeStep > 5 ? 'activeicon' : '' } ${activeStep === 5 ? 'completedicon' : ''}`}>
                    <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
                      5 {/* <BsStars /> */}
                    </div>
                    <div className={`mt-2 text-sm icontext-sm  ${activeStep >= 5 ? 'activesteptext' : '' }`}>
                    {formData.MainCategories.category_name ==="Engagement Ring"  ?
                      <div className="lgnowrap"> Aesthetic </div> :
                      formData.MainCategories.category_name === "Dress Ring"  ? 
                      <div>Motif </div>:
                      formData.MainCategories.category_name === "Wedding Band"  ? 
                      <div>Type</div>:
                      <div>Motif </div>
                      }
                      </div>
                  </div>
          }

{  (formData.MainCategories.category_name.trim() === catname2)    &&
                  <div  onClick={activeStep > 5?() => onStepClick4(1):null} className={`flex flex-col items-center ${activeStep > 5 ? 'activeicon' : '' } ${activeStep === 5 ? 'completedicon' : ''}`}>
                    <div className="w-10 h-10 rounded-full iconbg flex items-center justify-center">
                      5 {/* <BsStars /> */}
                    </div>
                    <div className={`mt-2 text-sm icontext-sm  ${activeStep >= 5 ? 'activesteptext' : '' }`}>
                    {formData.MainCategories.category_name ==="Engagement Ring"  ?
                      <div className="lgnowrap"> Aesthetic </div> :
                      formData.MainCategories.category_name === "Dress Ring"  ? 
                      <div>Motif </div>:
                      formData.MainCategories.category_name === "Wedding Band"  ? 
                      <div>Type</div>:
                      <div>Motif </div>
                      }
                      </div>
                  </div>
          }


        
      </div>
      </div>
    </div>
  );
}

export default Stepper;
