import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import Loading from "../shared/Loading";
import PopOver from "../shared/PopOver";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const MetalType = ({ next, back ,setpperback1,setpperback2,setpperback3,setpperback4}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({ metal: null });
  const [metaldata, setMetalData] = useState([]);
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [activestepval, setActiveSetpval] = useState(3);
  const [backval, setBackVal] = useState(4);
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const [mbDisabled, setmbDisabled] = useState(true);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleItemClick = (type, id) => {
    setmbDisabled(true)
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [type]: id,
    }));
  };

  useEffect(() => {
    if (selectedItems.metal) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItems]);

  useEffect(() => { 

    if (formData.MainCategories.category_name.trim() === "Wedding Band") {
      setActiveSetpval(4);
   
      if(formData?.MultiCenterStone1){   
      setBackVal(17);
      }else if(formData?.CenterStoneSingle){   
        setBackVal(18);
      } else{     
        setBackVal(10);
      }

    } else if (formData.MainCategories.category_name.trim() === "Bracelet") {
      setActiveSetpval(3);
      if(formData?.MultiCenterStone1){  
        setBackVal(17);
        } else{
          setBackVal(16);
        }
    } else if (formData.MainCategories.category_name.trim() === "Dress Ring") {
      setActiveSetpval(3); 
      if(formData?.MultiCenterStone1){  
        setBackVal(17);
        } else{
          setBackVal(2);
        }
    } else if (formData.MainCategories.category_name.trim() === "Pendant") {
      setActiveSetpval(3); 
      if(formData?.CenterStoneSingle?.stonevalue){  
        setBackVal(18);
        } else{
          setBackVal(2);
        }
    } else if (formData.MainCategories.category_name.trim() === "Earrings") {
      setActiveSetpval(3);
     
      if(formData?.CenterStoneSingle?.stonevalue){  
        setBackVal(18);
        } else{
          setBackVal(2);
        }
    } else {
      setActiveSetpval(4);
    }
  }, []);

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView();
    if (selectedItems.metal) {
      const metalType = metaldata.find(
        (metal) => metal.id === selectedItems.metal
      )?.name;
      const metalTypeId = metaldata.find(
        (metal) => metal.id === selectedItems.metal
      )?.id;
      const metalId = metaldata.find(
        (metal) => metal.id === selectedItems.metal
      )?.metal_id;

      const metal_form = metaldata.find(
        (metal) => metal.id === selectedItems.metal
      )?.metal_form;
      
      updateFormData("MetalType", {
        metaltype: metalType,
        metaltype_id: metalTypeId,
        metal_form: metal_form,
        metalid: metalId,
      });
      console.log(formData);
      // next();
      if (formData.MainCategories.category_name.trim() == "Wedding Band") {
        //next(7);
        setPopoverOpen(!PopoverOpen);
      } else {
        next(6);
      }
    } else {
      setError("Please select metal type");
    }
  };

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/metaltype`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())

      .then((metaldata) => {
        setMetalData(metaldata.metal_types);
        handleItemClick('metal',formData.MetalType.metaltype_id) 
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false on error as well
      });

   

     
  }, []);

  return (
    <div>
      <div className="flex">
        <div>
          <h1> {formData.MainCategories.category_name} </h1>
          <p className="lightfont">
            <SubTitle></SubTitle>
          </p>
        </div>

        <div className="show-mobile">
          <img
            src={formData.MainCategories.category_img}
            width="120px"
            alt="Main Image"
          />{" "}
        </div>
      </div>

      <Stepper   onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4} activeStep={activestepval}></Stepper>
      <div className="show-mobile">
      { mbDisabled && <h4 className="mbselected">Selected</h4>} 
      <ul className="flex selectedvallist ">
              {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

              {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}
                {formData?.CenterStoneSingle?.stonevalue && (
             <li>{formData.CenterStoneSingle.stonevalue}</li>
              )}
              {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
              
              {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )} 

              {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )}
            
 
            {formData?.MultiCenterStone1?.stone1 && (
            <li>{formData.MultiCenterStone1.stone1.stonevalue}</li>
             )}
             {(  formData?.MultiCenterStone2?.stone2?.stonevalue !== undefined) && (
            <li>{formData.MultiCenterStone2.stone2.stonevalue}</li>
             )} 
           
              {formData?.CenterStone?.stonevalue && (
                <li> {formData.CenterStone.stonevalue} </li>
              )}
              {formData?.CenterStone?.shapevalue && (
                <li> {formData.CenterStone.shapevalue} </li>
              )}
              {formData?.CenterStone?.sizevalue && (
                <li> {formData.CenterStone.sizevalue} </li>
              )}
              {formData?.DesignCrown?.crowntype && (
                <li> {formData.DesignCrown.crowntype} </li>
              )}
              {formData?.DesignShank?.shanktype && (
                <li> {formData.DesignShank.shanktype} </li>
              )}
              {selectedItems.metal && (
                <li className="selected-value">
                  {
                    metaldata.find((metal) => metal.id === selectedItems.metal)
                      .name
                  }
                </li>
              )}
            </ul>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p hide-mobile">
          <div className="mainImage">
            <div className="bg-white">
              <img
                src={formData.MainCategories.category_img}
                alt="Main Image"
              />
            </div>
            
            <ul className="flex selectedvallist ">
              {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

              {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}
                {/* {formData?.CenterStoneSingle?.stonevalue && (
             <li>{formData.CenterStoneSingle.stonevalue}</li>
              )} */}
              {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
              
              {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )} 
  {formData?.CenterStoneSingle?.stonevalue && (
             <li>{formData.CenterStoneSingle.stonevalue}</li>
              )}
              {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )}
            
 
            {formData?.MultiCenterStone1?.stone1 && (
            <li>{formData.MultiCenterStone1.stone1.stonevalue}</li>
             )}
                {(formData?.MultiCenterStone2?.stone2?.stonevalue !== undefined) && (
            <li>{formData.MultiCenterStone2.stone2.stonevalue}</li>
             )} 
           
              {formData?.CenterStone?.stonevalue && (
                <li> {formData.CenterStone.stonevalue} </li>
              )}
              {formData?.CenterStone?.shapevalue && (
                <li> {formData.CenterStone.shapevalue} </li>
              )}
              {formData?.CenterStone?.sizevalue && (
                <li> {formData.CenterStone.sizevalue} </li>
              )}
              {formData?.DesignCrown?.crowntype && (
                <li> {formData.DesignCrown.crowntype} </li>
              )}
              {formData?.DesignShank?.shanktype && (
                <li> {formData.DesignShank.shanktype} </li>
              )}
              {selectedItems.metal && (
                <li className="selected-value">
                  {
                    metaldata.find((metal) => metal.id === selectedItems.metal)
                      .name
                  }
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-5">
          {error && <div className="text-red text-center">{error}</div>}
          {/* Content for the second column */}
          <div className="alignbtns">
            <div className="space-y-6">
              <AccordionItem
                title={
                  <div className="text-left">
                    <span>Select metal type</span>
                    <p className="lightfont">
                      Choose which metal you want your ring made with
                    </p>
                  </div>
                }
                content={
                  loading ? ( // Show loader if loading is true
                    <Loading />
                  ) : (
                    <ul className="grid grid-cols-2 md:grid-cols-2 gap-4  ">
                      {metaldata.map((metal) => (
                        <li
                          className={`flex justify-between ${
                            selectedItems.metal === metal.id ? "active" : ""
                          }`}
                          onClick={() => handleItemClick("metal", metal.id)}
                          key={metal.id}
                        >
                          <span className="flex gap-2">
                            <span
                              style={{ fontSize: "24px" }}
                              className={`${metal.name}icon`}
                            >
                              <img width={40} src={metal.url} />
                            </span>
                            <div>{metal.name} </div>
                          </span>

                          <input
                            type="radio"
                            checked={selectedItems.metal === metal.id}
                            onChange={() => {}}
                          />
                        </li>
                      ))}
                    </ul>
                  )
                }
                isActive={activeIndex === 0}
                handleClick={() => handleClick(0)}
              />
            </div>
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={() => back(backval)}>
              {" "}
              <span>{"<"}</span> Go back
            </button>
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit}
            >
              Next <span>{">"}</span>
            </button>
          </div>
        </div>
      </div>

      {PopoverOpen && (
        <div>
          <div className="backdrop">
            <PopOver isOpen={handleSubmit}   next={next} />
          </div>
        </div>
      )}

    </div>
  );
};

export default MetalType;
