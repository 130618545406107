import React, { useState, useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import { useForms } from "../actions/FormContext";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import ThankYou from "../shared/ThankYou";
import PrivacyPolicy from "../shared/PrivacyPolicy";
import { useDropzone } from "react-dropzone";
import SocialMedia from "../shared/SocialMedia";
import RatingForm from "./Rating";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Mainimage from "../shared/Mainimage";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <div
      className="flex accorHeader cursor-pointer items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </div>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

function QuickInfo({ backtohome,back,mycurency,tradername }) {
 
  let minrange;
  let maxrange ;
 
  const maxCount = 300; // Maximum character count
 
  const [inputLength, setInputLength] = useState(0); // Initial character count is 0
  const [selectedImages, setSelectedImages] = useState([]);
  const [activeIndex1, setActiveIndex1] = useState(true);
  const [activeIndex2, setActiveIndex2] = useState(false);
  const [activeIndex3, setActiveIndex3] = useState(false);
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [PopoverOpenrating, setPopoverOpenrating] = useState(false);
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const [openPrivacy, setopenPrivacy] = useState(false);
  const[placeholdertxt,sePlaceHoldertext]=useState("")
  const [activeIndex, setActiveIndex] = useState(0);
  const [imgactiveIndex, setImgActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({ shape: [] });
  const [slidervaluebudget, setSliderValuebudget] = useState(0);
  const [slidervaluesoon, setSliderValuesoon] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(183);
  const [qformData, setFormData] = useState({
    in_customer_name: "",
    in_customer_contact: "",
    in_customer_email: "",
    in_customer_occasion: "",
    in_additionalinfo: "",
    in_timelines: "4 Weeks",
    in_customer_city:"",
    in_customer_country:selectedCountry
  });
  const [images, setImages] = useState([]);
  
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const selectedImages = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          selectedImages.push(reader.result);
        }
      };
      reader.readAsDataURL(file);
    });

    setImages([...images, ...selectedImages]);
  };
  if(formData.showPricepover?.pricepop == 0 ){
   minrange =  mycurency+"1,000";
  maxrange = mycurency+"5,000";
  }else{ 
      minrange = formData.QuotePrice.estimated_minprice && formData.QuotePrice.estimated_minprice;
  maxrange = formData.QuotePrice.estimated_maxprice && formData.QuotePrice.estimated_maxprice;
  }

  const [range, setRange] = useState([1, 50000]);
  const handleChange = (newRange) => {
    setRange(newRange);
  };
  // const dminval = parseInt(formData.QuotePrice.estimated_minrange);
  // const dmaxval = parseInt(formData.QuotePrice.estimated_maxrange);
 
 
  // const dminval = formData.CustomRing?.myring !== undefined 
  //               ? parseInt(formData.QuotePrice.estimated_minrange)
  //               : 1000;  

const defaultValue = 1000;  // replace 1000 with your default value
let dminval; 
if (formData.CustomRing?.myring !== undefined) {  
    dminval = formData.showPricepover?.pricepop !==0 ? parseInt(formData.QuotePrice.estimated_minrange) : defaultValue;
} else {
    dminval = defaultValue;
}

 
const defaultValue2 = 5000; // replace 10000 with your default value
let dmaxval;  
if(formData.CustomRing?.myring !== undefined) {
    dmaxval = formData.showPricepover?.pricepop !== 0 ? parseInt(formData.QuotePrice.estimated_maxrange) : defaultValue2;
} else {
    dmaxval = defaultValue2;
}
 
  const [minValue, setMinValue] = useState(100);
  const [maxValue, setMaxValue] = useState(15000);
  const [sliderValue, setSliderValue] = useState(2500);
  const [inputErrors, setInputErrors] = useState({});
  const [shapedata, setShapeData] = useState([]);
  const [getminval, setGetminval] = useState(minrange || mycurency+"1,000");
  const [getmaxval, setGetmaxval] = useState(maxrange || mycurency+"5,000");
  const [errorsName, seterrorsName] = useState(true);
  const [errorsEmail, seterrorsEmail] = useState(true); 
   
  const [submitform, setsubmitform] = useState(false)
  const [myimage, setFiles] = useState([]);
  const [invalidfile, setInvalidfile] = useState(false);
  const [filelengh, setFilelengh] = useState(false);
  const [countriesdata, setCountries] = useState([]); 
  


  useEffect(() => { 
    fetch(`${apiurl}/ring-builder/countries`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
     // .then((stonedata) => setStoneData(stonedata.design_types))
     .then((countriesdata) => { 
      setCountries(countriesdata.countries);    
     // handleItemClick('stone',formData.WeddingCrown.weddingid) 
      
    })
      .then((responsedata) => {
        
    
      })
      .catch((error) => {
        console.log(error);
     
      });
  }, []);


 

  const handleToggleAccordion1 = () => {
    setActiveIndex1(!activeIndex1);
  };
  const handleToggleAccordion2 = () => {
    setActiveIndex2(!activeIndex2);
  };
  const handleToggleAccordion3 = () => {
    setActiveIndex3(!activeIndex3);
  };

  const handleMaxValueChange = (event) => {
    const newMaxValue = parseFloat(event.target.value);
    setMaxValue(newMaxValue);
    if (newMaxValue < sliderValue) {
      setSliderValue(newMaxValue);
    }
  };
  
  
  const handleItemClick = (type, id) => {
    if (type === "shape") {
      let updatedShapes = [...selectedItems.shape];
      if (updatedShapes.includes(id)) {
        updatedShapes = updatedShapes.filter((item) => item !== id);
      } else {
        updatedShapes.push(id);
      }
      setSelectedItems((prevState) => ({ ...prevState, shape: updatedShapes }));
    }
    // Handle other item types if any...
     
    
  };
  const handleSliderBudget = (newValue) => {
    const isFixedValue = Object.keys(marksbudget).includes(newValue.toString());
    setSliderValuebudget(isFixedValue ? newValue : slidervaluebudget);
    setFormData((prevState) => ({
      ...prevState,
      budget: isFixedValue ? marksbudget[newValue] : "",
    }));
  };
  const handleSliderSoon = (newValue2) => {
    if (formData.QuotePrice.currency_symbol) {
      var rang1 = !isNaN(newValue2[0])
        ? formData.QuotePrice.currency_symbol +
          "" +
          newValue2[0].toLocaleString()
        : newValue2[0];
      var rang2 = !isNaN(newValue2[1])
        ? formData.QuotePrice.currency_symbol +
          "" +
          newValue2[1].toLocaleString()
        : newValue2[1];
    } else {
      var rang1 = !isNaN(newValue2[0])
        ? mycurency+newValue2[0].toLocaleString()
        : newValue2[0];
      var rang2 = !isNaN(newValue2[1])
        ? mycurency+newValue2[1].toLocaleString()
        : newValue2[1];
    } 
    
    if (rang1 !== undefined || rang2 !== undefined) {
        setGetminval(rang1);
        setGetmaxval(rang2);    
     }
    const isFixedValue2 = Object.keys(markssoon).includes(newValue2.toString());
    setSliderValuesoon(isFixedValue2 ? newValue2 : slidervaluesoon);
    if(isFixedValue2)
    {
      setFormData((prevState) => ({
        ...prevState,
        in_timelines: markssoon[newValue2],
      }));
    }
    
    
  };

    

  const validateForm = () => {
    let errors = {};
    let hasErrors = false;

    if (!qformData.in_customer_name.trim()) {
      errors.in_customer_name = "Username is required";
      hasErrors = true; 
    }
    
    setInputErrors(errors);
    return hasErrors;
  };

  
  const openPopoverRating = () => {
    setPopoverOpenrating(!PopoverOpenrating)
   // setPopoverOpen(!PopoverOpen);
  };
  const openPopover = () => {    
   setPopoverOpen(!PopoverOpen);
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const submitFormData = () => {
    
    setsubmitform(true);  
    formSubmission();
   // handleFormSubmit();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); 
    document.getElementById("root").scrollIntoView();
    if(formData.CustomRing?.myring !== undefined) { 
      openPopoverRating(); 
      if(!submitform){
        return;
      }
    }
   
    formSubmission();
   
  };

  const formSubmission =()=>{

    const ShapeSelectedval = selectedItems.shape;

    const row_id = formData.CustomRing?.myring
      ? formData.ImagesData.media.row_id
      : null;
    const in_selected_mediaid = formData.CustomRing?.selectedimage
      ? formData.CustomRing.selectedimage
      : null;

    if (!validateForm()) {
      
      const postformData = {
        ...qformData,
        ShapeSelectedval,
        quoted_minval:formData.QuotePrice.estimated_minprice,
        quoted_maxval:formData.QuotePrice.estimated_maxprice,
        in_budgetfrom:getminval,
        in_budgetto: getmaxval,
        row_id: row_id,
        in_selected_mediaid: in_selected_mediaid
      };

      const imagesData = new FormData();

      myimage.forEach(file => {
        imagesData.append('ring_builder_files[]', file);
      });

      // Append other form fields
      Object.keys(postformData).forEach(key => {
        imagesData.append(key, postformData[key]);
    });


      fetch(`${apiurl}/ring-builder/customer`, {
        method: "POST",
        //body: JSON.stringify(postformData),
        body: imagesData,
        headers: {
          Authorization: bearertoken,
        },
      })
        .then((response) => {
          if (response.ok) {
            // Process successful response
            console.log("Data posted successfully");
            // Reset the form
            setFormData({
              in_customer_name: "",
              in_customer_contact: "",
              in_customer_email: "",
              in_customer_occasion:"",
              in_additionalinfo: "",
              in_customer_city:"",
              in_customer_country:selectedCountry
            });
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle error
        });
      
        
      openPopover();
      // Reset the form
      // setFormData({ in_customer_name: "", in_customer_contact: "", in_customer_email: "", in_additionalinfo: "" });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'in_customer_contact' && value.length > 15) { 
      return;
    }
    if (name === 'in_customer_country') { 
      setSelectedCountry(e.target.value);
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    validateForm();
     if(e.currentTarget.id=="message"){ 
    setInputLength(e.target.value.length);
     }
  };
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
   
  formData.CustomRing?.myring == undefined ?
  sePlaceHoldertext(`Your design, your style! Let us know how you'd like to personalize your product. Share every detail and preference with us to craft a truly unique piece.`)
  :
  sePlaceHoldertext(`This design is totally customizable! Do you have specific preferences or ideas in mind? How would you like to personalize it? Let us know here! `)

  
}, [ ]);
  useEffect(() => {
    
    if (
      formData.CustomRing?.myring == undefined ?
      ( qformData.in_customer_name.trim() &&
      selectedImages.length >= 1 &&
     // qformData.in_customer_contact.trim() &&
      qformData.in_customer_email.trim() &&
      isChecked ) :
      (  qformData.in_customer_name.trim() && 
      qformData.in_customer_email.trim() &&
      isChecked)
     
    ) {
      
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputErrors, isChecked, selectedImages]);

  const marksbudget = {
    0: 100,
    1000: "1,000",
    5000: "5,000",
    10000: "10,000",
    15000: "15,000+",
  };
  const markssoon = {
   // 0: "2 Weeks",
    0: "4 Weeks",
    33: "6 Weeks",
    66: "8 Weeks",
    // 60: "10 Weeks",
    // 75: "15 Weeks",
    100: "10+ Weeks",
  };

  const gotoPolicy = () => {
    document.getElementById("root").scrollIntoView();
    setopenPrivacy(!openPrivacy);
    // window.parent.postMessage("sendpost")
  };

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/categories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((shapedata) => setShapeData(shapedata.categories))
      .then((shaperesponse) => {
        //setStoneData(shapedata.stoneshape);
        // setShapeLoading(false); // Set loading to false when data is fetched
      })

      .catch((error) => {
        console.log(error);
        //  setShapeLoading(false); // Set loading to false on error as well
      });
  }, []);

  // const shapedata = [
  //   { id: "Ring", name: "Ring" },
  //   { id: "Earrings", name: "Earrings" },
  //   { id: "Pendant", name: "Pendant" },
  //   { id: "Bracelet", name: "Bracelet" },
  // ];
  const selectedshapeItems = shapedata.filter((shape) =>
    selectedItems.shape.includes(shape.id)
  );

  function log(value) {
    console.log(value); //eslint-disable-line
  }

  const { getRootProps, getInputProps } = useDropzone({
    //accept: "image/jpeg, image/png, application/pdf",
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/gif': ['.gif'],
      'application/pdf': ['.pdf'],
    },
    maxSize:5242880, // up to 5 MB
    multiple: true,
    maxFiles: 4,
    onDropRejected: (fileRejections, evt) => { 
    
      fileRejections.forEach(({ file, errors }) => { 
          errors.forEach((error) =>{ 
            setInvalidfile(true)
          setTimeout( ()=>{
            setInvalidfile(false)
          },5000 )
         
         // alert(`Invalid file format or size, File should be in jpeg, png, gif, pdf format and not more than 5MB in size`)
        }
         );
      });
  },
    onDrop: (acceptedFiles) => {
      
      let uploadimglength = (selectedImages.length + acceptedFiles.length);

       if(uploadimglength >= 5) {
        setFilelengh(true);
        setTimeout( ()=>{
          setFilelengh(false);
        },5000 )
        return false;
       }
      setFiles(oldfiles => [...oldfiles, ...acceptedFiles]);
       const newImages = acceptedFiles.map((file) =>
       
        Object.assign(file, {
           preview: file.type === 'application/pdf' ? "../Images/pdficon.png" : URL.createObjectURL(file)
           })
        );
     
      setSelectedImages((prevImages) => [...prevImages, ...newImages]);
    
    },

  });

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => {
      const imagesCopy = [...prevImages];
      imagesCopy.splice(index, 1);
      setFiles(prevImages => [...imagesCopy]);
      return imagesCopy;
    });

    if (imgactiveIndex === index) {
      setImgActiveIndex(null);
    }
  };

  const handleActiveImage = (index1) => {
    setImgActiveIndex((prevIndex) => (prevIndex === index1 ? null : index1));
  };
  const closeInvalidfile = ()=>{ 
    setInvalidfile(false)
  }
  const closeMaxlength = ()=>{ 
    setFilelengh(false);
  }
  
  const imagePreviews = selectedImages.map((image, index) => (
    
    <div
      key={index}
      className={`relative mb-10 ${imgactiveIndex === index ? "activepreive" : ""}`}
      onClick={() => handleActiveImage(index)}
    >
      <img
        src={image.preview}
        alt={`Preview ${index + 1}`}
        style={{ width: "100px", height: "100px" }}
      />
      <div
        className="imgcloseicon absolute cursor-pointer"
        onClick={() => handleRemoveImage(index)}
      >
        X
      </div>
    </div>
  ));
  return (
    <div>
      <h1>Just some quick information </h1>
      <p className="lightfont mb-textcenter"> 
        This will help us review your request and get back in touch shortly  
      </p>
      
    
      

      <div className="flex flex-wrap mt-4">
        {formData.CustomRing?.myring !== undefined  ? (
          <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p">
             <Mainimage></Mainimage>
          </div>
        ) : null}

        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-5">
          <form onSubmit={handleFormSubmit}>
            <div className="flex flex-wrap -mx-2" style={{rowGap:"10px"}}>
              <div className="w-full sm:w-1/2 px-2">
                <label className="block mb-2">
                  <h6>Name {errorsName && <span className="errormsg">*</span>} </h6>
                  <div className="flex items-center" style={{columnGap:"5px"}}> 
                  <input
                    type="text"
                    id="username"
                    name="in_customer_name"
                    value={qformData.in_customer_name}
                    onChange={handleInputChange}
                    className="w-full border radius-15 border-gray-300 p-2"
                    placeholder="Name"
                  />
                    {/* <span  className={`errormsg ${isDisabled ? "disable" : ""}`} >*</span>  */}
                    
                    </div>
                </label>
                 
              </div>

              <div className="w-full sm:w-1/2 px-2">
                <label className="block mb-2">
                  <h6>Phone</h6>
                  <input
                    type="number" maxLength="6"
                    id="phoneNo"
                    name="in_customer_contact"
                    value={qformData.in_customer_contact}
                    style={{appearance: "textfield", MozAppearance: "textfield"}}
                    onChange={handleInputChange}
                    className="w-full border  radius-15 border-gray-300 p-2"
                    placeholder="Phone"  />
                </label>
                {/* {inputErrors.in_customer_contact && <span className="errormsg">{inputErrors.in_customer_contact}</span>} */}
              </div>



           
              

              <div className="w-full sm:w-1/2 px-2">
                <label className="block mb-2">
                  <h6>Email {errorsEmail && <span className="errormsg">*</span>} </h6>
                  <div className="flex items-center" style={{columnGap:"5px"}}> 
                  <input
                    type="email"
                    id="email"
                    name="in_customer_email"
                    value={qformData.in_customer_email}
                    onChange={handleInputChange}
                    className="w-full border  radius-15 border-gray-300 p-2"
                    placeholder="Email"
                  />
                     
                     </div> 
                </label>
             {/* {inputErrors.in_customer_email && <span className="errormsg">{inputErrors.in_customer_email}</span>}  */}
             </div> 
             <div className="w-full sm:w-1/2 px-2">
             <label className="block mb-2">
                  <h6>What's the occasion?</h6>
                  <div className="flex items-center" style={{columnGap:"5px"}}> 
                  <input
                    type="text"
                    id="occasion"
                    name="in_customer_occasion"
                    value={qformData.in_customer_occasion}
                    onChange={handleInputChange}
                    className="w-full border  radius-15 border-gray-300 p-2"
                    placeholder="Occasion"
                  />
                     
                     </div> 
                </label>

              </div>

              <div className="w-full sm:w-1/2 px-2">
                <label className="block mb-2">
                  <h6> Country <span className="errormsg">*</span></h6>
                  <div className="flex items-center" style={{columnGap:"5px"}}> 
                   <select value={selectedCountry} onChange={handleInputChange} className="w-full border radius-15 border-gray-300 p-2"
                    name="in_customer_country" id="country-select">
                        {countriesdata.map((country, index) => (
                          <option   key={index} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                   </select>  

                

                  {/* <input
                    type="text"
                    id="country"
                    name="in_customer_country"
                    value={qformData.in_customer_country}
                    onChange={handleInputChange}
                    className="w-full border radius-15 border-gray-300 p-2"
                    placeholder="Your Name"
                  /> */}
                    {/* <span  className={`errormsg ${isDisabled ? "disable" : ""}`} >*</span>  */}
                    
                    </div>
                </label>
                 
              </div>

              <div className="w-full sm:w-1/2 px-2">
                <label className="block mb-2">
                  <h6>City</h6>
                  <input
                    type="text" 
                    id="city"
                    name="in_customer_city"
                    value={qformData.in_customer_city}
                    style={{appearance: "textfield", MozAppearance: "textfield"}}
                    onChange={handleInputChange}
                    className="w-full border  radius-15 border-gray-300 p-2"
                    placeholder="City"  />
                </label>
                {/* {inputErrors.in_customer_contact && <span className="errormsg">{inputErrors.in_customer_contact}</span>} */}
              </div>

              <div className="w-full px-2">
                <label className="block ">
                  <h6>Your personalization notes</h6>
                  <textarea style={{paddingBottom:"20px"}} maxLength={maxCount}
                    className="w-full border no-resize radius-15 border-gray-300 p-2"
                    id="message"
                    name="in_additionalinfo"
                    value={qformData.in_additionalinfo}
                    onChange={handleInputChange}
                    rows="5"
                    placeholder={placeholdertxt}
                  ></textarea>
                </label>
                <div style={{position:"relative", fontSize:"12px", color:"#969696",  right:"10px"}} className="text-right ">{maxCount - inputLength} characters left</div>
                {/* {inputErrors.in_additionalinfo && <span className="errormsg">{inputErrors.in_additionalinfo}</span>} */}
              </div>

              <div className="w-full px-2" id="scrolltoCenter">
                <div className="flex justify-between mt-4 mb-drectioncol sm:m-0">
                  <div>
                    <h6>Upload any images for our reference  { formData.CustomRing?.myring == undefined && <span className="errormsg">*</span> }</h6>
                    <p className="lightfont">
                     Do you want your product to look a certain way? Upload up to 4 jewellery images here for our reference
                    </p>
                    {
                     <div className="flex"> <div  className={`fade w-auto boxborder ${filelengh ? 'heightauto' : 'fade-out height0'}  blue-font relative flex   items-center gap-3`}>
                      You can upload maximum 4 files
                      <span className=" cursor-pointer" onClick={() => closeMaxlength()} ><IoIosCloseCircleOutline /> </span>
                     </div>
                     </div>
                    }
                    {
                       <div className="flex"><div  className={`fade w-auto boxborder ${invalidfile ? 'heightauto' : 'fade-out height0'}  blue-font relative flex   items-center gap-3`}>
                      Invalid file format or size  
                     <span className=" cursor-pointer" onClick={() => closeInvalidfile()} ><IoIosCloseCircleOutline /> </span>
                     </div>   </div>
                     } 
                  </div>

                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className="uplodfilebtn border-gray-300 cursor-pointer">
                      Upload File <GrAttachment />{" "}
                    </p>
                  </div>
                </div>

                <div className="flex previmages ">{imagePreviews}</div>
              </div>
            </div>

            <div className="space-y-6">
              <AccordionItem
                title={
                  <div className="text-left">
                    <span>What is your budget?</span> 
                    {/* {formData.CustomRing?.myring !== undefined && (
                       <p className='lightfont hidepriceval'>We quoted you {formData.QuotePrice.estimated_minprice}  to  {formData.QuotePrice.estimated_maxprice}</p>
                      // <p className="lightfont col-hide">
                      //   We quoted you {getminval} to {getmaxval}
                      // </p>
                    )} */}
                  </div>
                }
                content={
                  <div className=" p-8">
                  
                    <Slider.Range
                      min={minValue}
                      max={maxValue}
                      defaultValue={[dminval, dmaxval]}
                      marks={marksbudget}
                      step={10}
                      onChange={handleSliderSoon}
                    />

                    {formData.CustomRing?.myring !== undefined ? (
                      <div className="flex gap-3 mt-10 mb-priceset">
                        From{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <span style={{ marginRight: '5px' }}>{formData.QuotePrice.currency_symbol}</span> */}

                          <input
                            readOnly
                            className="border border-gray-300 w-16 inputborder-val"
                            type="text"
                            value={getminval}
                          />
                        </div>
                        to{" "}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <span style={{ marginRight: '5px' }}>{formData.QuotePrice.currency_symbol}</span> */}
                          <input
                            readOnly
                            className="border border-gray-300  w-16 inputborder-val"
                            type="text"
                            value={getmaxval}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex gap-3 mt-10">
                        From{" "}
                        <input
                          readOnly
                          className="border border-gray-300 w-16 inputborder-val"
                          type="text"
                          value={getminval}
                        />
                        to{" "}
                        <input
                          readOnly
                          className="border border-gray-300  w-16 inputborder-val"
                          type="text"
                          value={getmaxval}
                        />
                      </div>
                    )}
                  </div>
                }
                isActive={activeIndex1}
                handleClick={handleToggleAccordion1}
              />
             
              <AccordionItem
                title="How soon do you want the product delivered?"
                content={
                  <div className="p-12 pt-6">
                    <Slider
                      min={0}
                      max={100} 
                      value={slidervaluesoon}
                      marks={markssoon}
                      onChange={handleSliderSoon}
                      step={null} // Setting step as null restricts dragging between points
                      dotStyle={{ borderColor: "grey" }}
                    />
                  </div>
                }
                isActive={activeIndex2 === true}
                handleClick={handleToggleAccordion2}
              />
               

              <AccordionItem
                title= {
                  <div className="text-left">
                    <span>Get matching item</span> 
                      <p className="lightfont col-hide"> For an additional cost  </p>                    
                  </div>
                  
                }
                content={
                  <ul className="grid grid-cols-2 md:grid-cols-2 gap-4">
                    {shapedata.map((shape) => (
                      <li
                        className={`flex justify-between ${
                          selectedItems.shape.includes(shape.id) ? "active" : ""
                        }`}
                        onClick={() => handleItemClick("shape", shape.id)}
                        key={shape.id}
                      >
                        <span className="flex gap-2">
                          <img width={40} src={shape.url} />
                          {shape.name}
                        </span>
                        <input
                          type="checkbox"
                          checked={selectedItems.shape.includes(shape.id)}
                          onChange={() => handleItemClick("shape", shape.id)}
                        />
                      </li>
                    ))}
                  </ul>
                }
                isActive={activeIndex3 === true}
                handleClick={handleToggleAccordion3}
              />

<div className="mb-10">
              <div className="privaicybox">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />{" "}
                By clicking submit, you agree to our{" "}
                <a
                  className="linktext cursor-pointer  lg-text-lg"
                  onClick={gotoPolicy}
                >
                  privacy policy
                </a>
              </div>
            </div>
            </div>
         

            <div className="flex justify-between mt-4 mb-4">
           
            <div className="gobackmob" id="feedBackPop" > 
          {formData.CustomRing?.myring !== undefined ?  
            <button className="backbtn" onClick={back}>
              <span>{"<"}</span> Go back
            </button> :
            
            <button className="backbtn" onClick={backtohome}>
              <span>{"<"}</span> Go back
            </button>
             }
             </div>

              <button
                disabled={isDisabled}
                className={`nextbtn ${isDisabled ? "disable" : ""}`}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
 
          {openPrivacy && (
            <div>
              <div className="backdrop">
                <PrivacyPolicy isOpen={gotoPolicy} />
              </div>
            </div>
          )}
           {PopoverOpenrating && (
            <div>
              <div className="backdrop mbpoptop" >
               <RatingForm  submitFormData ={submitFormData} isOpen={openPopoverRating} closepoup2={openPopover}></RatingForm>
              </div>
            </div>
          )}

          {PopoverOpen && (
            <div>
              <div className="backdrop mbpoptop"  >
                <ThankYou closepoup={openPopover} setgotohome={backtohome} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuickInfo;
