import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import PopOver from "../shared/PopOver";
import Loading from "../shared/Loading";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const DesignAesthetic = ({ next, back, setpperback1,setpperback2,setpperback3,setpperback4 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({ stone: null });
  const [stonedata, setStoneData] = useState([]);
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [activestepval, setActiveSetpval] = useState(5);
  const [mbDisabled, setmbDisabled] = useState(true); 
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

   

  const handleItemClick = (type, id) => {
    setmbDisabled(true)
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [type]: id,
    }));
    // const aestheticType = stonedata.find(stone => stone.id === selectedItems.stone)?.name;
    // updateFormData('designaestheticlabel', { aesthetictype:aestheticType });
    //
  };

  useEffect(() => {
    if (selectedItems.stone) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItems]);

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView();
    if (selectedItems.stone) {
      // setIsOpen(!isOpen);
      const aestheticType = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.name;
      const aestheticId = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.id;
      const subcategory = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.category_id;
      updateFormData("DesignAesthetic", { aesthetictype: aestheticType,aestheticid:aestheticId,sub_category_id: subcategory });
      // next();
      if (formData.MainCategories.category_name == "Engagement Ring1") {
        next(7);
      } else if (formData.MainCategories.category_name.trim() == "Dress Ring") {
        next(13);
      } 
      setPopoverOpen(!PopoverOpen);
    } else {
      //setError("Please select aesthetic");
    }
  };
  useEffect(() => {
    if (formData.MainCategories.category_name.trim() === "Wedding Band") {
      setActiveSetpval(4);
    } else if (formData.MainCategories.category_name.trim() === "Bracelet") {
      setActiveSetpval(4);
    } else if (formData.MainCategories.category_name.trim() === "Dress Ring") {
      setActiveSetpval(4);
    } else if (formData.MainCategories.category_name.trim() === "Earrings") {
      setActiveSetpval(4);
    } else if (formData.MainCategories.category_name.trim() === "Pendant") {
      setActiveSetpval(4);
    } else {
      setActiveSetpval(5);
    }
  }, []);

  useEffect(() => {
   
    fetch(`${apiurl}/ring-builder/aesthentic`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
      method: "POST",
      body: JSON.stringify({
        category_id: formData.MainCategories.category_id,
      }),
    })
      .then((response) => response.json())
      .then((stonedata) => {
        setStoneData(stonedata.aesthetics);
        handleItemClick('stone',formData.DesignAesthetic.aestheticid) 
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false on error as well
      });
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="flex">
        <div>
          {" "}
          <h1> {formData.MainCategories.category_name} </h1>
          <p className="lightfont">
            <SubTitle></SubTitle>
          </p>
        </div>

        <div className="show-mobile">
          <img
            src={formData.MainCategories.category_img}
            width="120px"
            alt="Main Image"
          />{" "}
        </div>
      </div>

      <Stepper   onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4}  activeStep={activestepval}></Stepper>
      <div className="show-mobile">
      { mbDisabled && <h4 className="mbselected">Selected</h4>} 
        <ul className="flex selectedvallist">
        {formData?.DressStyle?.styletype && (
            <li>{formData.DressStyle.styletype}</li>
          )}
          {formData?.PendentStyle?.pendenttype && (
            <li>{formData.PendentStyle.pendenttype}</li>
          )}
             {formData?.CenterStoneSingle?.stonevalue && (
             <li>{formData.CenterStoneSingle.stonevalue}</li>
              )}
          {formData?.BraceletStyle?.bracelettype && (
            <li>{formData.BraceletStyle.bracelettype}</li>
          )}

          {formData?.EarringsCrown?.earringstype && (
            <li>{formData.EarringsCrown.earringstype}</li>
          )}

          {formData?.WeddingStyle?.weddingtype && (
            <li>{formData.WeddingStyle.weddingtype}</li>
          )}
          {formData?.WeddingCrown?.weddingtype && (
            <li>{formData.WeddingCrown.weddingtype}</li>
          )}

        
             {formData?.MultiCenterStone1?.stone1 && (
            <li>{formData.MultiCenterStone1.stone1.stonevalue}</li>
             )}
                {(  formData?.MultiCenterStone2?.stone2?.stonevalue !== undefined) && (
            <li>{formData.MultiCenterStone2.stone2.stonevalue}</li>
             )} 

          {formData?.CenterStone?.stonevalue && (
            <li> {formData.CenterStone.stonevalue} </li>
          )}
          {formData?.CenterStone?.shapevalue && (
            <li> {formData.CenterStone.shapevalue} </li>
          )}
          {formData?.CenterStone?.sizevalue && (
            <li> {formData.CenterStone.sizevalue} </li>
          )}
          {formData?.DesignCrown?.crowntype && (
            <li> {formData.DesignCrown.crowntype} </li>
          )}
          {formData?.DesignShank?.shanktype && (
            <li> {formData.DesignShank.shanktype} </li>
          )}
          {formData?.MetalType?.metaltype && (
            <li> {formData.MetalType.metaltype} </li>
          )}
          {selectedItems.stone && (
            <li className="selected-value">
              {stonedata.find((stone) => stone.id === selectedItems.stone).name}
            </li>
          )}
        </ul>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p hide-mobile">
          <div className="mainImage">
            <div className="bg-white">
              <img
                src={formData.MainCategories.category_img}
                alt="Main Image"
              />
            </div>
            <ul className="flex selectedvallist">
            {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )}
              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}
               
              {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

             {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

            {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
             {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )}
  {formData?.CenterStoneSingle?.stonevalue && (
             <li>{formData.CenterStoneSingle.stonevalue}</li>
              )}
            {formData?.MultiCenterStone1?.stone1 && (
            <li>{formData.MultiCenterStone1.stone1.stonevalue}</li>
             )}
                {(  formData?.MultiCenterStone2?.stone2?.stonevalue !== undefined) && (
            <li>{formData.MultiCenterStone2.stone2.stonevalue}</li>
             )} 

             
              {formData?.CenterStone?.stonevalue && (
                <li> {formData.CenterStone.stonevalue} </li>
              )}
              {formData?.CenterStone?.shapevalue && (
                <li> {formData.CenterStone.shapevalue} </li>
              )}
              {formData?.CenterStone?.sizevalue && (
                <li> {formData.CenterStone.sizevalue} </li>
              )}
              {formData?.DesignCrown?.crowntype && (
                <li> {formData.DesignCrown.crowntype} </li>
              )}
              {formData?.DesignShank?.shanktype && (
                <li> {formData.DesignShank.shanktype} </li>
              )}
              {formData?.MetalType?.metaltype && (
                <li> {formData.MetalType.metaltype} </li>
              )}
              {selectedItems.stone && (
                <li className="selected-value">
                  {
                    stonedata.find((stone) => stone.id === selectedItems.stone)
                      .name
                  }
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-5">
          {error && <div className="text-red text-center">{error}</div>}
          {/* Content for the second column */}
          <div className="space-y-6">
            <AccordionItem
              title={
                <div className="text-left">
                  <span>Choose design aesthetic</span>
                  <p className="lightfont">
                    Your ring design will be inspired by concepts and patterns
                    from your chosen option
                  </p>
                </div>
              }
              content={
                loading ? ( // Show loader if loading is true
                  <Loading />
                ) : (
                  <ul className="grid grid-cols-2 sm:grid-cols-1 gap-4 customlist-mb">
                    {stonedata.map((stone) => (
                      <li
                        className={`flex justify-between ${
                          selectedItems.stone === stone.id ? "active" : ""
                        }`}
                        onClick={() => handleItemClick("stone", stone.id)}
                        key={stone.id}
                      >
                        <span className="flex gap-2">
                          <img width={40} src={stone.url} />
                          <div className="line-inherit">
                            <div>
                              {stone.name}
                              {stone.is_popular == 1 && (
                                <span className="popular-tag">Popular</span>
                              )}
                            </div>
                            <p className="lightfont">{stone.description}</p>
                          </div>
                        </span>
                        <input
                          type="radio"
                          checked={selectedItems.stone === stone.id}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                  </ul>
                )
              }
              isActive={activeIndex === 0}
              handleClick={() => handleClick(0)}
            />
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={back}>
              {" "}
              <span>{"<"}</span> Go back
            </button>
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit}
            >
              Next <span>{">"}</span>
            </button>
          </div>
        </div>
      </div>

      {PopoverOpen && (
        <div>
          <div className="backdrop">
            <PopOver isOpen={handleSubmit}   next={next} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DesignAesthetic;
