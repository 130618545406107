import React, { useEffect  } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
function LoadingTimer({loadingsize,whoopstext}) {

  useEffect(() => {
    const timer = setTimeout(() => {      
      whoopstext() ;
    }, 100 * 1000);    
    return () => clearTimeout(timer); 
  }, []);

  return (
   <div  >
    <div  className= {`h-full flex  items-center justify-center  ${loadingsize && "lodingspace"} `}> 
    <div style={{fontSize:"25px", fontWeight:"bold"}} className= {`loader ${loadingsize && "LgIcon"}` }> 
    <CountdownCircleTimer
    isPlaying
    duration={100}
    strokeWidth={5}
    size={80}
    colors={['#003459', '#003459', '#003459', '#003459']}
    colorsTime={[7, 5, 2, 0]}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
  
    </div>
    </div>

    
    </div>
  )
}

export default LoadingTimer