import React from "react";

function SubmitYourDesign({ next2 }) {
  return (
    <div className="flex flex-col md:flex-row justify-between submitdesign mt-5 mb-5">
      <div className="md:w-10/12 ">
        <h4>Or submit your dream design here!</h4>
        <p className="headingtitle m-0" style={{margin:"0px"}}> Already have a design created? No problem! Submit it here. We'll get in touch with you & manufacture to your liking!
        </p>
      </div>
      <button
        id="your-button-id"
        className="submitbtn mt-4 md:mt-0 md:ml-4"
        onClick={next2}
      >
        Submit  
      </button>
    </div>
  );
}

export default SubmitYourDesign;
